<template>
  <div>
    <div class="container">
      <table>
        <!-- <tr>
          <td><input id="chk_fta" class="td_title normal-checkbox" ref="chk_fta" type="checkbox" :checked='(String(notice).indexOf("원산지증명") === -1? false : true)' /></td>
          <td class="td_title">
            <div class="td_title tooltip"><label for="chk_fta">원산지증명(FTA 통관) : +10,000원</label>
              <span class="tooltiptext tooltip-bottom">1. 원산지증명가능국가 :프랑스, 독일, 영국, 미국, 중국, 호주, 일본 <br><br>2. 물건을 받은 후에 대행국가에서 생산되었다는 게 확인이 되어야 원산지증명 진행이 가능합니다.</span>
            </div>
          </td>
        </tr> -->
        <tr>
          <td><input id="chk_packing" class="td_title normal-checkbox" ref="chk_packing" type="checkbox" :checked='(String(notice).indexOf("포장보완비용") === -1? false : true)' /></td>
          <td class="td_title">
            <div class="td_title tooltip"><label for="chk_packing">추가포장 : +5,000원</label>
              <span class="tooltiptext tooltip-bottom">추가포장을 하셔도 파손에 대해서는 GEEK9에서 책임지지 않습니다.</span>
            </div>
          </td>          
        </tr>
        <tr>
          <td><input id="chk_picture" class="td_title normal-checkbox" ref="chk_picture" type="checkbox" :checked='(String(notice).indexOf("사진촬영비용") === -1? false : true)' /></td>
          <td class="td_title">
            <div class="td_title tooltip"><label for="chk_picture">상품사진 : +5,000원</label>
              <span class="tooltiptext tooltip-bottom">상품의 가품, 파손 여부는 GEEK9에서 책임지지 않습니다.</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><input id="chk_wine" class="td_title normal-checkbox" ref="chk_wine" type="checkbox" :checked='(String(notice).indexOf("와인") === -1? false : true)' /></td>
          <td class="td_title">
            <div class="td_title tooltip"><label for="chk_wine">와인(프랑스만 대행 가능) : +8,000원 x 수량</label>
              <span class="tooltiptext tooltip-bottom">상품의 가품, 파손 여부는 GEEK9에서 책임지지 않습니다.</span>
            </div>
          </td>
        </tr>              
      </table>
    </div>
  </div> 
</template>

<script>

export default {
  props: {
    notice: String
  }
}
</script>
<style scoped>
input {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: -40px;
}
</style>