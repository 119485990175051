<template>
  <div id="orderItem">
    <table class="order_info" style="width:100%">
      <tr><td><span ref="idx">{{idx}}</span></td><td/>
        <td style="text-align:right;" colspan="2">
          <Button raised size="small" @click='copy(idx)' label="복사" style="margin-right:5px;" />
          <Button raised size="small" @click='remove(idx)' label="삭제" />
        </td>
      </tr>
    </table>
    <table class="dynamic-table">
      <tr style="height:100px;">
        <td style="width:100%;">
          <table class="order_sub_table" style="table-layout:fixed;">
            <tbody>
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'상품을 구매할 수 있는 해외 사이트 주소(URL)'">상품링크*</div>
                    <div style="width:100%;">
                      <input class="normalInput" v-if="grouped === undefined" ref="url" type="url" :value="(idx === 1)? route.url:''" @focusout="checkUrl" placeholder="상품판매링크">
                      <input class="normalInput" v-else ref="url" type="url" :value="grouped.URL" @focusout="checkUrl" placeholder="상품판매링크">
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'상품링크에서 판매하고 있는 상품의 이름'">상품명*</div>
                    <div style="width:100%;">
                      <input class="normalInput" v-if="grouped === undefined" ref="name" @keyup="this.checkReg($event)" type="text" :value="(idx === 1)? route.name:''" placeholder="영어로 써주세요">
                      <input class="normalInput" v-else ref="name" type="text" @keyup="this.checkReg($event)" :value="grouped.NAME" placeholder="영어로 써주세요">
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div class="link" style="min-width:100px;" v-tooltip="'상품 사진의 주소(URL)'">사진링크</div>
                    <div style="width:100%;">
                      <input class="normalInput" v-if="grouped === undefined" ref="imgUrl" type="url" :value="(idx === 1)? route.imgUrl:''" placeholder="상품사진링크">
                      <input class="normalInput" v-else ref="imgUrl" type="url" :value="grouped.IMG_URL" placeholder="상품사진링크">
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'상품을 구매한 국가의 화폐 단위 기준 판매 가격'">해외가격*</div>
                    <div style="width:100%;">
                      <input class="normalInput" v-if="grouped === undefined" ref="price" type="number" :value="(idx === 1)? route.sellPrice:''" placeholder="숫자만 써주세요">
                      <input class="normalInput" v-else-if="String(grouped.ORIGIN_PRICE).indexOf(' ') !== -1" ref="price" type="number" :value="grouped.ORIGIN_PRICE.split(' ')[0]" placeholder="숫자만 써주세요">
                      <input class="normalInput" v-else ref="price" type="number" :value="grouped.ORIGIN_PRICE" placeholder="숫자만 써주세요">
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'구매링크에서 판매하고 있는 상품의 구매 수량'">구매수량*</div>
                    <div style="width:100%;">
                      <input class="normalInput" ref="amount" type="number" :value="(grouped === undefined || grouped.AMOUNT === undefined)? '':grouped.AMOUNT" placeholder="숫자만 써주세요">
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'(직접 구매하신 경우에만 입력) 구매하신 해외 사이트에서 주문번호가 제공됩니다.'">주문번호*</div>
                    <div style="width:100%;">
                      <input class="normalInput" v-if="grouped === undefined" ref="orderNumber" type="text" placeholder="없는 경우 1 입력">
                      <input class="normalInput" v-else ref="orderNumber" type="text" placeholder="없는 경우 1 입력" :value="grouped.ORDER_NUMBER">
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'(직접 구매하신 경우에만 입력) 해외 사이트에서 배송을 보냈을 때 제공해주는 택배 조회 번호'">추적번호</div>
                    <div style="width:100%;">
                      <input class="normalInput" ref="trackingNumber" type="text" :value="(grouped === undefined || grouped.TRACKING_NUMBER === undefined)? '':grouped.TRACKING_NUMBER" placeholder="나중에 입력해주셔도 됩니다">
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'배송대행시에는 대행비 없이 배송비만 발생, 구매대행시에는 대행비 발생하고 배송비 별도)'">대행비</div>
                    <div style="width:100%;">
                      <input class="normalInput" v-if="grouped === undefined" ref="wonPrice" type="text" :value="(idx === 1)? route.wonPrice:''" disabled placeholder="견적이 나오면 이메일로 안내 드립니다">
                      <input class="normalInput" v-else ref="wonPrice" type="text" :value="grouped.WON_PRICE === undefined ? grouped.SELL_PRICE : grouped.WON_PRICE" disabled placeholder="견적이 나오면 이메일로 안내 드립니다">
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" v-tooltip="'구매한 사이트 사이트 이름/브랜드'">사이트명</div>
                    <div style="width:100%;">
                      <input class="normalInput" v-if="grouped === undefined" ref="brand" type="text" :value="(idx === 1)? route.brand:''" placeholder="사이트명">
                      <input class="normalInput" v-else ref="brand" type="text" :value="grouped.BRAND" placeholder="사이트명">
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    idx: Number,
    route: Object,
    grouped: Object
  },
  data() { 
    return { 
      imgUrl: '',
    } 
  },
  methods: {
    // reg that allow English, number, 특수문자
    async checkUrl() {
      // if(this.$refs.name.value !== '') return
      let type
      let url = this.$refs.url.value
      if(url.indexOf('vinted.') !== -1) {
        type = 'VINTED'
      // } else if(url.indexOf('carousell.com.hk') !== -1) {
      //   type = 'CAROUSELL'
      } else {
        return
      }
      if(confirm(type + " 제품인가요?")) {
        this.emitter.emit('dialog', {flag:true, title:'상품정보를 읽어오고 있습니다.'})
        await this.api.axiosGql(type, {'type': type, 'url': url}) 
        .then(async (res) => { 
          if(res.data.error !== undefined) {
            alert(res.data.error)
            this.$notify(res.data.error)
          } else {
            const wonPrice = res.data.sellPrice
            const imgUrl = res.data.imgUrl
            const name = res.data.name
            const sellPrice = res.data.pagePrice
            
            this.$refs.wonPrice.value = wonPrice
            this.$refs.name.value = name
            this.$refs.price.value = sellPrice
            this.$refs.amount.value = '1'
            this.$refs.orderNumber.value = '1'
            this.$refs.imgUrl.value = imgUrl
            this.$refs.brand.value = type.toLowerCase()
          }
        }) 
        .catch(async (error) => { 
          this.$notify(error)
          console.log(error)
          
        }) 
        .finally(() => {
          this.emitter.emit('dialog', {flag:false})
        })
      }
    },
    checkReg(event) {
      const regExp = /[^0-9a-zA-Z!@#$%^&*()_+\-\s=[\]{};':"\\|,.<>/?]/g;
      const del = event.target;
      if (regExp.test(del.value)) {
        del.value = del.value.replace(regExp, '');
      }
    },
    copy(idx) {
      this.$emit("copy", idx)
    },
    remove(idx) {
      this.$emit("remove", idx)
    },
    autoSet() {
      this.$notify(this.idx)
    },
    showImg() {
      let imgUrl = this.$refs.imgUrl.value
      if(imgUrl.indexOf('http') === -1) {
        imgUrl = "https://" + this.$refs.imgUrl.value
      }
      let reg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\\/?%&=]*)?/
      if(reg.test(imgUrl)) window.open(imgUrl, 'img', 'width=250, height=250')
      else this.$notify('잘못된 사진링크입니다.')
    }
  }
}
</script>
