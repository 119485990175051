<template>
<div>
  <div style="width:100%; display:block;">
      <div class="card main" >
        <table style="width:100%; text-align:left">
          <tr style="color:red">
            <td>{{idx}}</td>
            <td style='text-align:right;width:100%;' @click='closeModal' class='link'>선택</td>
          </tr>
          <tr>
            <td>{{name}}</td>
            <td>{{phone}}</td>
          </tr>
          <tr>
            <td>{{clearance}}</td>
            <td>{{mail}}</td>
          </tr>
          <tr>
            <td colspan="2">({{ addrZip }}) {{addrRoad}} {{addrDetail}}</td>
          </tr>
        </table>
      </div> 
  </div>
</div>  
</template>
<script>
export default {
  props: {
    idx: String,
    name: String,
    clearance: String,
    addrZip: String,
    addrRoad: String,
    addrDetail: String,
    phone: String,
    mail: String
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.$emit("setModal", {name: this.name, clearance: this.clearance, addrRoad: this.addrRoad, addrZip: this.addrZip, addrDetail: this.addrDetail, phone:this.phone, mail: this.mail});
    },
  }
}
</script>